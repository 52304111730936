import { Body1, tokens } from '@fluentui/react-components';
import { IChatMessage } from '../../libs/models/ChatMessage';

/*
 * Function to check if date is today.
 */
export function isToday(date: Date) {
    return date.toDateString() === new Date().toDateString();
}

/*
 * Function to render the date and/or time of a message.
 */
export function timestampToDateString(timestamp: number, alwaysShowTime = false) {
    const date = new Date(timestamp);
    const dateString = date
        .toLocaleDateString(['he-IL'], {
            month: 'numeric',
            day: 'numeric',
        })
        .replace(/\D/g, '/');
    const timeString = date.toLocaleTimeString(['he-IL'], {
        hour: 'numeric',
        minute: '2-digit',
    });

    return date.toDateString() !== new Date().toDateString()
        ? alwaysShowTime
            ? dateString + ' ' + timeString // if the date is not today and we are always showing the time, show the date and time
            : dateString // if the date is not today and we are not always showing the time, only show the date
        : timeString; // if the date is today, only show the time
}

/*
 * Function to create a command link
 */
export function createCommandLink(command: string) {
    const escapedCommand = encodeURIComponent(command);
    const createCommandLink = `<span style="text-decoration: underline; cursor: pointer" data-command="${escapedCommand}" onclick="(function(){ let chatInput = document.getElementById('chat-input'); chatInput.value = decodeURIComponent('${escapedCommand}'); chatInput.focus(); return false; })();return false;">${command}</span>`;
    return createCommandLink;
}

/*
 * Function to format chat text content to remove any html tags from it.
 */
export function formatChatTextContent(messageContent: string): string {
    const contentAsString = messageContent
        .trim()
        .replace(/^sk:\/\/.*$/gm, (match: string) => createCommandLink(match))
        .replace(/^!sk:.*$/gm, (match: string) => createCommandLink(match));
    return contentAsString;
}

export function removeHtmlTags(messageContent: string): string {
    return messageContent
        .replace(/<[^>]+>/g, '')
        .replace('```html', '')
        .replace('```', '');
}

/*
 * Formats text containing `\n` or `\r` into paragraphs.
 */
export function formatParagraphTextContent(messageContent = '') {
    messageContent = messageContent.replaceAll('\r\n', '\n\r');

    return (
        <Body1>
            {messageContent.split('\n').map((paragraph, idx) => (
                <p
                    key={`paragraph-${idx}`}
                    style={
                        paragraph.includes('\r')
                            ? {
                                  display: 'flex',
                                  marginLeft: tokens.spacingHorizontalL,
                              }
                            : {
                                  overflowWrap: 'anywhere',
                              }
                    }
                >
                    {paragraph}
                </p>
            ))}
        </Body1>
    );
}

/*
 * Function to replace citation links with indices matching the citation list.
 */
// export function replaceCitationLinksWithIndices(formattedMessageContent: string, message: IChatMessage) {
//     const citations = message.citations;
//     if (citations) {
//         citations.forEach((citation, index) => {
//             const citationLink = citation.link;
//             console.log(citationLink);
//             formattedMessageContent = formattedMessageContent.replaceAll(citationLink, index.toString());
//         });
//     }

//     return formattedMessageContent;
// }

/**
 * Replaces citation placeholders in the formattedMessageContent with numbered span elements.
 * Each span displays a sequential number and contains the citation text in a data attribute.
 *
 * @param formattedMessageContent - The message content containing citation placeholders.
 * @param message - The chat message object containing citations.
 * @returns The updated message content with citation placeholders replaced by span elements.
 */
export function replaceCitationLinksWithIndices(messageContent: string, message: IChatMessage): string {
    const citations = message.citations;

    let formattedMessageContent: string = messageContent.replace(/&lt;/g, '<').replace(/&gt;/g, '>');

    if (citations && citations.length > 0) {
        const citationMap = new Map<string, number>();
        let counter = 1;

        const citationRegex = /<\[(section-[A-Za-z0-9]{4})\]>/g;
        formattedMessageContent = formattedMessageContent.replace(citationRegex, (_, p1: string) => {
            // Check if this citation link has already been assigned an index
            if (!citationMap.has(p1)) {
                citationMap.set(p1, counter);
                counter++;
            }
            const index = citationMap.get(p1);

            const spanElement = `<span
    class="citation-${message.id}"
    data-citation-link="${p1.replace('section-', '')}"
    style="
      display: inline-flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      margin-right: 3px;
      cursor: pointer;
      user-select: none;
      gap: 5px;
      width: 17px;
      height: 17px;
      background: rgb(233, 242, 249);
      border-radius: 3px;
      color: rgb(62, 138, 81);
      white-space: nowrap;
      flex: 0 0 auto;
    ">${index}</span>`;

            return spanElement;
        });
    }

    return formattedMessageContent;
}

/**
 * Gets message of error
 */
export function getErrorDetails(error: unknown) {
    return error instanceof Error ? error.message : String(error);
}
