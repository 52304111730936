import { saveAs } from 'file-saver';
import { asBlob } from 'html-docx-ts';

interface DocxConversionOptions {
    fileName?: string;
    isRtl?: boolean;
}

export const convertHtmlToDocx = async (htmlContent: string, options: DocxConversionOptions = {}): Promise<void> => {
    try {
        const { fileName = 'document', isRtl = false } = options;
        const styledHtmlContent = `
            <html>
                <head>
                <meta charset="UTF-8">
                    <style>
                        body {
                            font-family: Arial, sans-serif;
                            font-size: 12pt;
                            line-height: 1.5;
                            direction: ${isRtl ? 'rtl' : 'ltr'};
                        }
                        span {
                            font-family: Arial, sans-serif;
                        }
                        .deleted {
                            color: red;
                            text-decoration: line-through;
                        }
                        .added {
                            color: blue;
                            text-decoration: underline;
                        }
                    </style>
                </head>
                <body>
                    ${htmlContent}
                </body>
            </html>
        `;

        console.log('styledHtmlContent:', styledHtmlContent);

        const blob = (await asBlob(htmlContent)) as Blob;
        saveAs(blob, `${fileName}.docx`);

        return Promise.resolve();
    } catch (error) {
        console.error('Error converting HTML to DOCX:', error);
        throw error;
    }
};
